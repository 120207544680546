import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { navigate } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "supporting-your-teen-if-a-loved-one-contracts-covid-19"
    }}>{`Supporting your teen if a loved one contracts COVID-19`}</h2>
    <Box display="flex" justifyContent="center" mdxType="Box">
  <Button onClick={() => navigate('/m2-parenting-in-pandemic/27-family-violence')} variant="contained" color="primary" mdxType="Button">
    Skip Topic
  </Button>
    </Box>
    <p>{`The pandemic and associated changes to our daily lives will affect us all differently. For some families, this may include a loved one becoming infected or dying from COVID-19.`}</p>
    <p>{`So how can you support your teen if someone close to them contracts COVID-19?`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m9/54-01-AUS-version.svg" alt="Support Teens" mdxType="SingleImage" />
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`If a loved one contracts COVID-19`}</li>
      <li parentName="ul">{`If a loved one dies during the pandemic`}</li>
    </ul>
    <SingleImage src="/images/m2/32-sensitive-topic.png" smallGridSize={8} gridSize={6} alt="Sensitive Topic Warning" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      